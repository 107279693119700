import React, { Component, Suspense } from 'react'
import { connect } from "react-redux";
import { HashRouter, Route, Routes } from 'react-router-dom'
import LoginGuard from "./components/guard";
import { CSpinner } from '@coreui/react-pro'
import setAuthToken from './utils/setAuthToken';
import jwtDecode from 'jwt-decode'
import { actLoginUser, logout } from "./store/actions/user";
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/login/Login'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes >          
            <Route
              path="/login"
              name="Login Page"
              element={<Login />}
            />
            <Route
              path="*"
              element={<LoginGuard path={"/"} component={DefaultLayout} />}
            />
          </Routes >          
        </Suspense>
      </HashRouter>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadUserProfile: user => {
        // console.log(user)
        const { token } = user;
        setAuthToken(token);
        const decoded = jwtDecode(token);
        dispatch(actLoginUser(decoded));
    //   dispatch(actLoginUser(user));
    },
    onCheckToken: () => {
      dispatch(logout())
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(App)
