import { GET_ERRORS} from '../constants/user';

const initialState = {
    isAuthenticated: false,
    user: {},
    errors: {}
};

const function_ = function(state = initialState, action) {
    switch (action.type){
        case GET_ERRORS:
            return action.payload
        default:
            return state;
    }
}

export default function_