import { LOGIN_USER, GET_ERRORS } from "../constants/user";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwtDecode from "jwt-decode";
import {SERVER} from "../constants/config";

export const requestLoginUSer = userData => {
  // console.log(userData)
  const params = {
    username: userData.username,
    password: userData.password,
    fingerprint: userData.fingerprint
  }
  
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  return dispatch => {
    axios
      .get(`${SERVER}/user/login`, {
        headers: headers,
        params: params
      })
      .then(res => {
        // console.log(res.data.detail)
        if (typeof res.data.detail !== "string") {
          const { token } = res.data.detail;
          localStorage.setItem("jwtToken", token);
          localStorage.setItem("userLogin", JSON.stringify(res.data.detail));
          setAuthToken(token);          
          const decoded = jwtDecode(token);      
          dispatch(actLoginUser(decoded));
        } else {
          alert("Tài khoản hoặc mật khẩu sai gòi.");
        }
      })
      .catch(err => {        
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.detail
        });
      });
  };
};

export const sendVerifyCode = (email, callback) => {
  // console.log(userData)
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  const formData = new FormData()
  formData.append('email', email)

  return dispatch => {
    axios
      .post(`${SERVER}/user/email/verify`, formData, {
        headers: headers,
        //params: params
      })
      .then(res => {
        callback(res.data)
      })
      .catch(err => {
        // dispatch({
        //   // type: GET_ERRORS,
        //   // payload: err.response
        // })
        console.log(err)
      })
  }
};


export const changePWD = (email, verify_code, new_password, callback) => {
  // console.log(userData)
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
  };

  const formData = new FormData()
  formData.append('email', email)
  formData.append('verify_code', verify_code)
  formData.append('password', new_password)

  return dispatch => {
    axios
      .post(`${SERVER}/user/update/password`, formData, {
        headers: headers,
        //params: params
      })
      .then(res => {
        callback(res.data)
      })
      .catch(err => {
        // dispatch({
        //   // type: GET_ERRORS,
        //   // payload: err.response
        // })
        console.log(err)
      })
  }
};

export const fetchUser = (phone, callback) => {  
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  };
  const params = {
    phone
  };

  // console.log(id)

  return dispatch => {
    axios
      .get(`${SERVER}/app/users/detail`, {
        headers: headers,
        params: params
      })
      .then(res => {
        callback(res.data)
      })
      .catch(err => {
        // dispatch({
        //   // type: GET_ERRORS,
        //   // payload: err.response
        // })
        console.log(err)
      })
  }
}

export const fetchUserType = (callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  }
  return dispatch => {
    axios
      .get(`${SERVER}/users/type`,{
        headers: headers
      })
      .then(res => {
        callback(res.data)}
      )
      .catch(console.log)
  }
}

export const updateUser = (params, callback) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("jwtToken"),
    fingerprint: "123456",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE"
  }
    
  // const username = decoded.username
  // const user_id = decoded.user_id
  const formData = new FormData()
  formData.append("id",params.userId);
  formData.append("full_name",params.userName);
  formData.append("is_active", params.active);
  formData.append("department_id", params.userDept);
  formData.append("phone", params.userPhone);
  formData.append("user_type_id", params.userType);
  formData.append("username", params.userAcc)

  // console.log(username)

  return dispatch => {
    axios
      .put(`${SERVER}/users`, formData, {
        headers: headers
      })
      .then(res => {
        callback(res.data)
      })
      .catch(console.log)
  }
}

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem('jwtToken')
    localStorage.removeItem('userLogin')
    setAuthToken(false)
    // dispatch(actLoginUser({}));
  }
}

export const actLoginUser = decoded => {
  return {
    type: LOGIN_USER,
    payload: decoded
  };
};
