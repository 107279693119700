import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthorize } from '../../utils/isLogin'
import jwtDecode from 'jwt-decode'
import { actLoginUser, logout } from "../../store/actions/user";
import setAuthToken from '../../utils/setAuthToken';
import { connect } from "react-redux";
const loginGuard = ({ component: Component, path }) => {
  const user = localStorage.getItem("userLogin")
  // const intervalID = setInterval(() => {
  //   this.setState({
  //     intervalID,
  //     time: new Date(Date.now()).toLocaleTimeString(),
  //     date: new Date(Date.now()).toLocaleDateString(),
  //   }, () => { })
  // }, 3000)
  if (user) {
    const company = JSON.parse(user)['company']
    if (company === 'tlc') {
      const decoded = jwtDecode(localStorage.jwtToken)
      const pathname = path
      const employee_code = decoded.employee_code
      const currentTime = new Date().getTime() / 1000
      //console.log(decoded)
      if(decoded.iat + 1800 < currentTime){
        //this.props.onCheckToken()
        return <Navigate to="/login" />
      }
      // console.log(isAuthorize(pathname))
      // console.log(pathname)
      if (isAuthorize(pathname)) {
        return <Component />
      }
      else {
        alert('Bạn không có quyền truy cập !')
        return <Navigate to={"/mgt/emp/" + employee_code} />
      }
    }
  }
  alert('Vui lòng đăng nhập !');
  return <Navigate to="/login" />

};

const mapDispatchToProps = dispatch => {
  return {
    onLoadUserProfile: user => {
        // console.log(user)
        const { token } = user;
        setAuthToken(token);
        const decoded = jwtDecode(token);
        dispatch(actLoginUser(decoded));
    //   dispatch(actLoginUser(user));
    },
    onCheckToken: () => {
      dispatch(logout())
    }
  };
};
export default connect (null,mapDispatchToProps) (loginGuard);
