import { combineReducers } from "redux";
import userReducer from './user';
import errorsReducer from './errors'
import changeState from './side'

const rootReducer = combineReducers({
  user: userReducer,
  errors: errorsReducer,
  side: changeState
});

export default rootReducer;